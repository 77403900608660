var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "text-end", attrs: { id: "ag-footer", outlined: "" } },
    [
      _c("v-card-text", { staticClass: "white--text", attrs: { id: "text" } }, [
        _vm._v(" " + _vm._s(_vm.versão) + " " + _vm._s(_vm.version) + " "),
        _c("br"),
        _vm._v(" " + _vm._s(_vm.builder) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }